import styled from 'styled-components';
import { OutlinedButton } from '../../styles/globalstyles';
export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`
export const Nav = styled.nav`
  position: fixed;
  width: 100vw;  
  top: 0;  
  z-index: 1;  
  background-color: unset; 
  color: ${props => props.theme.color.white.regular};
  padding: 30px 40px;
  transition: 0.5s ease-out;
  
  ${({ hasScrolled }) => hasScrolled && `
  background: #ffffffe6;
  padding: 15px 20px;
`}

  
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 10px 0;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const Brand = styled.div`
font-family: ${props => props.theme.font.bold};
${props => props.theme.font_size.large};
color: ${props => props.theme.color.white.regular};
width: min-content;
${({ hasScrolled }) => hasScrolled && `
  color: #000;
  `}
`;

export const NavItem = styled.div`
margin:auto;
min-width: fit-content;
padding: 10px 15px;
font-weight:600;
a{
  font-family: ${props => props.theme.font.primary};
  color: ${props => props.theme.color.white.regular};
  ${({ hasScrolled }) => hasScrolled && `
  color: #000;
  `
  }
`;

export const LoginButton = styled(OutlinedButton)`
${({ hasScrolled }) => hasScrolled && `
color: #000;
border: 1px solid black;
  `}
`;