import styled from 'styled-components';

export const WhiteStyledContainer = styled.div`
height:300px;
width:100%;
margin-top:-120px;
background: rgba(255,255,255,1)
`
export const StyledContainer = styled.div`
height:750px;
width:100%;
margin-top:-120px;
background: rgb(88,255,215);
background: linear-gradient(90deg, rgba(63, 181, 156,1) 0%, rgba(0,183,117,1) 49%);
`

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  a{
    text-decoration: none;
  }
`;

export const FlexMargin = styled(Flex)`
`;

export const MainText = styled.h1`
text-align: center;
`;

export const SubText = styled.p`
max-width: 70%;
`;
