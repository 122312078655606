import * as React from "react";
import styled from "styled-components";
import { Flex } from "../../components/maintext/styles";
import { Link } from "gatsby";

const FooterContainer = styled.div`
background-color: ${props=>props.theme.color.verde};
`;

const FooterPage = () => {
  return <React.Fragment>

  <FooterContainer style={{ padding: '40px' }}>
    <Flex>
      <Link to="/" style={{textDecoration:'none', color:'#fff', }}>
        <h3 style={{fontSize:'16px'}}>
      APISheet.co
        </h3>
      </Link>
      
    </Flex>
      
  </FooterContainer>
  </React.Fragment>
}

export default FooterPage;
