// Theme

const theme = {
  font: {
    primary: `Montserrat`,
    secondary: `'HK Grotesk Medium'`,
    light: `'HK Grotesk Light'`,
    normal: `'HK Grotesk Normal'`,
    medium: `'HK Grotesk Medium'`,
    semibold: `Montserrat`,
    bold: `Montserrat`,
    extrabold: `'HK Grotesk Extra Bold'`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px;",
  },

  color: {
    verde:'#00b775',
    amarelo:'#e8ce95',
    primary: "#071435",
    secondary: "#534bae",
    accent: "#192576",
    branquinho: "#f6f6f6",
    roxinho: "#908cbf",
    vermelhinho: "#ff8a8a",
    azulzinho: "#c0d6f1",
    azulforte: "#2e2b80",
    lilas: "#da80e1",
    background: {
      white: "#8279d8",
      light: "#908cbf",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#65856D",
      light: "#564F62",
      regular: "#071435",
    },
  },
  screen: {
    xxxs: "325px",
    xxs: "425px",
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
