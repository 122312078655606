import { Nav, StyledContainer, Brand, NavItem, LoginButton } from "../howitworks/styles";
import { Link } from "gatsby";
import { useEffect, useState, } from "react";
import * as React from 'react';



const NavBar = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);
  
  const handleScroll = (event) => {
    const scrollTop = window.pageYOffset
    if (scrollTop > 10) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  }


  return (<Nav hasScrolled={hasScrolled}>
      <StyledContainer style={{alignItems:'center'}}>
        <Link to="/" style={{ textDecoration: 'none', maxWidth: 'min-content' }}>
          <Brand hasScrolled={hasScrolled}>
            <span>APISheet</span><span style={{color:'#e8ce95'}}>.</span><span>co</span>
          </Brand>
        </Link>
        <StyledContainer style={{
          maxWidth: 'min-content', marginLeft: '30px', alignItems: 'center'
        }}>
          {navItem({ text: "usecases", to: "/usecases", hasScrolled })}
          {navItem({ text: "how it works", to: "/#howitworks",hasScrolled })}
        </StyledContainer>
        <a href="https://console.apisheet.co" style={{ textDecoration: 'none' }}>
          <LoginButton hasScrolled={hasScrolled}>Sign In</LoginButton>
        </a>
      </StyledContainer>
    </Nav>

  )
}

export default NavBar;
function navItem({ text, to, hasScrolled }) {
  return <NavItem hasScrolled={hasScrolled}>
    <Link to={to} style={{ textDecoration: 'none' }}>
      <span>{text}</span>
    </Link>
  </NavItem>;
}

